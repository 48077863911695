#auth-essential-layout {
    display: flex;
    flex-flow: row nowrap;
    min-height: 100vh;
    width: 100%;
    padding: 0;
    overflow-x: hidden;
}

#auth-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    width: 55%;
    padding: 30px 0;
}

#auth-image-container img {
    width: 65%;
}

#auth-image-container>div h3 {
    font-weight: 700;
    font-size: 1.75rem;
    text-align: center;
    margin: 1rem 0;
}

#auth-image-container>div p {
    margin: 0 auto;
    width: 75%;
    text-align: center;
}

#auth-form-container {
    width: 45%;
    background: linear-gradient(110.63deg, #193670 10.43%, #171959 65.49%);
    display: flex;
    gap: 1.5rem;
    padding: 30px 0;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
}

#auth-form-container>* {
    text-align: center;
    max-width: 60%;
}

#auth-form-container h2 {
    color: white;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
}

#auth-form-container h6 {
    color: white;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
}

#auth-form-container form {
    background: white;
    width: 60%;
    padding: 2rem;
    box-shadow:
        0px 0.9928057193756104px 1.1039999723434448px 0px #01010105,
        0px 2.2079999446868896px 2.759999990463257px 0px #01010108,
        0px 5.519999980926514px 4.415999889373779px 0px #0101010A,
        0px 5.519999980926514px 5.519999980926514px 0px #0101010D,
        0px 16.559999465942383px 11.039999961853027px 0px #0101010F,
        0px 34.748199462890625px 44.15999984741211px 0px #01010112;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    border-radius: 10px;
}

#user-auth-form label,
.default-theme-input>label {
    margin-bottom: 5px;
    font-size: 13px;
    text-align: start;
    display: flex;
    justify-content: space-between;
}

#user-auth-form>input,
.sales-dropdown,
.default-theme-input>input {
    font-size: 16px;
    margin-bottom: 15px;
    width: calc(100% - 2rem);
    padding: 0.75rem;
    border-radius: 8px;
    border: 1px solid #BBBCCB;
    background: #fafcff;
    box-shadow:
        0px 0.9928057193756104px 1.1039999723434448px 0px #01010105,
        0px 2.2079999446868896px 2.759999990463257px 0px #01010108,
        0px 5.519999980926514px 4.415999889373779px 0px #0101010A,
        0px 5.519999980926514px 5.519999980926514px 0px #0101010D,
        0px 16.559999465942383px 11.039999961853027px 0px #0101010F,
        0px 34.748199462890625px 44.15999984741211px 0px #01010112;
    outline: none;
    color: black;
}

.default-theme-input>textarea {
    font-size: 16px;
    margin-bottom: 15px;
    width: calc(100% - 2rem);
    padding: 0.75rem;
    border-radius: 8px;
    border: 1px solid #BBBCCB;
    background: #fafcff;
    box-shadow:
        0px 0.9928057193756104px 1.1039999723434448px 0px #01010105,
        0px 2.2079999446868896px 2.759999990463257px 0px #01010108,
        0px 5.519999980926514px 4.415999889373779px 0px #0101010A,
        0px 5.519999980926514px 5.519999980926514px 0px #0101010D,
        0px 16.559999465942383px 11.039999961853027px 0px #0101010F,
        0px 34.748199462890625px 44.15999984741211px 0px #01010112;
    outline: none;
    color: black;
}

#user-auth-form>input[type="email"],
#user-auth-form>input#email,
#user-auth-form>input[type="password"] {
    font-weight: 600;
    opacity: .5;
}

#user-auth-form>input::placeholder {
    font-weight: 400;
    color: black;
}

#user-auth-form input:focus,
#user-auth-form>input#email:focus {
    opacity: 1;
    background: #EFF3F9;
    border: 1px solid #171959a1;
}

form#user-auth-form p {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin: 25px auto;
    color: black;
}

#auth-form-container p {
    color: white;
    font-weight: 700;
    font-size: 14px;
}

#auth-form-container button {
    height: 45px;
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(120.03deg, #15D0B8 6.72%, #3910DE 88.76%);
    border: none;
    outline: none;
    color: white;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
}

#auth-form-container>button {
    width: calc(60% + 4rem);
    max-width: 100%;
}

.label-icon {
    position: relative;
    height: 24px;
    width: 24px;
    right: 24px;
    top: 40px;
    cursor: pointer;
    z-index: 1;
}

#user-auth-form .message {
    margin-bottom: 2rem;
}

#user-auth-form .message * {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
}

#user-auth-form input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-bottom: 0;
    opacity: 1;
}

#dont-ask-container {
    display: flex;
    gap: 8px;
    margin: 25px 0;
}

#dont-ask-container div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}

#dont-ask-container div label {
    margin: 0;
    cursor: pointer;
}

#dont-ask-container #learn-more {
    background: white;
    color: transparent;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0em;
    text-align: left;
    background: linear-gradient(120.03deg, #15D0B8 6.72%, #3910DE 88.76%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: none;
    cursor: pointer;
}

#not-a-robot-container {
    display: flex;
    padding: .85rem;
    border-radius: 10px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    margin: 0 0 70px 0;
    border: 1px solid #BBBCCB;
    box-shadow: 0px 12px 12px 0px #32324714;
}

#not-a-robot-container input[type="checkbox"] {
    width: 25px;
    height: 25px;
    opacity: 1;
}

#not-a-robot-container label {
    margin: 0;
}

#collect-on-web {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    margin: 5px 0 10px 0;
}

#collect-on-web>div {
    display: flex;
    gap: 5px;
}

#collect-on-web input[type="radio"] {
    width: 20px;
    height: 20px;
    opacity: 1;
    background: none;
}

#collect-on-web label {
    width: fit-content;
}

.label-icon.dropdownIco {
    right: 30px;
    top: 38px;
    width: unset;
    height: unset;
}

.sales-dropdown {
    margin-bottom: 40px;
    text-align: start;
    opacity: 1;
    font-weight: 400;
}

div.brand-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

div.brand-logo h2 {
    font-weight: 400;
    font-size: 56px;
}

div.brand-logo img {
    height: 84px;
}

div#bottom-texts-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    margin: auto;
    gap: 10px;
    width: 90%;
}

div#bottom-texts-container h3 {
    text-align: start;
    font-size: 20px;
}

div#bottom-texts-container img {
    width: fit-content;
    height: fit-content;
}

div#bottom-texts-container div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

div#bottom-texts-container p {
    margin: 0;
    width: 100%;
    text-align: start;
}

#user-auth-form p#reset-password-message {
    font-size: 16px;
    font-weight: 600;
    text-align: start;
    line-height: 30px;
    text-transform: capitalize;
}

#password-reseted {
    width: 100%;
    height: 100vh;
    background: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
}

#password-reseted>div {
    position: relative;
    padding: 30px 0;
}

#password-reseted #shadow {
    border-radius: 10px;
    position: absolute;
    height: 35%;
    width: 100%;
    bottom: 0;
    box-shadow:
        0px 0.8137752413749695px 0.9049180746078491px 0px #01010105,
        0px 1.8098361492156982px 2.2622952461242676px 0px #01010108,
        0px 4.524590492248535px 3.6196722984313965px 0px #0101010A,
        0px 4.524590492248535px 4.524590492248535px 0px #0101010D,
        0px 13.573770523071289px 9.04918098449707px 0px #0101010F,
        0px 28.482131958007812px 36.19672393798828px 0px #01010112;
}

#password-reseted p {
    font-size: 20px;
    text-align: center;
    width: 50%;
    font-weight: 700;
    margin: -45px auto auto;
}

#action-panel-header .brand-logo {
    width: fit-content;
}

#action-panel-header {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
}

#action-panel-header .help-btn {
    border-right: 1px solid #dedede;
}

#action-panel-header .help-btn>button {
    background: linear-gradient(110.63deg, #193670 10.43%, #171959 65.49%);
    border-radius: 100px;
    color: white;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    gap: 12px;
}

#action-panel-header>div {
    display: flex;
    align-items: center;
}

#action-panel-header>div>div {
    padding: 0px 25px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.profile-pricture-wrapper {
    width: 60px;
    height: 60px;
    background: #EFF3F9;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#action-panel-leftside {
    display: flex;
    gap: 8px;
    flex-flow: column nowrap;
    padding: 0 18px;
    width: fit-content;
    height: calc(100vh - 80px);
}

#action-panel-leftside>div {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px;
    border-radius: 5px;
    width: 8.5rem;
}

#action-panel-leftside>div.selected {
    background: linear-gradient(110.63deg, #193670 10.43%, #171959 65.49%);
    color: white;
}