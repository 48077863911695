.App {
    overflow-x: hidden;
}

#pages-wrapper {
    display: flex;
    gap: 15px;
    margin: 15px;
}
.withnobg{
    border: none !important;
    background: no-repeat !important;
}
#input-file-upload {
    display: none;
  }
  
.signup_err {
    margin: 4px 0 0 0;
    margin-bottom: 12px;
    text-align: left;
    font-size: 13px;
    color: #e64646;
}
#logo-page-container {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(110.63deg, #193670 10.43%, #171959 65.49%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.text-danger{
    font-size: 10px;
    color: red;
    font-weight: bold;
}
#logo-page-container .circle-container {
    width: 100%;
    height: 100vh;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    overflow: hidden;
}

#logo-page-container .circle {
    background: linear-gradient(110.63deg, #193670 10.43%, #171959 65.49%);
    box-shadow: 0px 34.7482px 44.16px rgb(1 1 1 / 7%);
    border-radius: 100%;
}

#logo-page-container div#logo-page-content {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    gap: 10px;
}

#logo-page-container h2 {
    letter-spacing: 5px;
    color: white;
    font-size: 6rem;
    font-weight: 400;
    position: relative;
    bottom: 5px;
}

.capital {
    text-transform: capitalize;
}

div.dropdown-options-wrapper {
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(100% + 5px);
    z-index: 1;
}

div.dropdown-options {
    padding: 15px;
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;
    background: #EFF3F9;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    text-align: start;
}

div.dropdown-options>div {
    display: flex;
    gap: 10px;
}

.popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-container {
    box-shadow: 0 0 10px -8px;
    background: #EFF3F9;
    border-radius: 10px;
    overflow: hidden;
}

.popup-container>* {
    padding: 15px 40px;
}

.popup-container>.popup-head {
    font-size: 18px;
    font-weight: 900;
    border-bottom: 1px solid #FFF;
}

.popup-container>.popup-head.white {
    background: white;
    border: none;
}

.popup-container>.popup-btns {
    display: flex;
    gap: 25px;
    justify-content: center;
}

.popup-container>.popup-btns>* {
    width: 50%;
    padding: 8px 16px;
    font-size: 17px;
    border-radius: 5px;
    box-shadow: 0px 24px 32px 0px #32324714,
        0px 16px 16px 0px #32324714,
        0px 4px 4px 0px #00000040;
}

.popup-container>.popup-content {
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;
}

#switch-wrapper {
    width: 60px;
    height: 30px;
    border-radius: 15px;
    background: #ccced1;
    display: flex;
    align-items: center;
}

#switch-circle {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    background: white;
    margin: 2px;
}

#menu-icon-wrapper {
    height: 100%;
    transition: all .5s ease;
    border-radius: 4px;
    padding: 0 !important;
}

#menu-icon-wrapper:hover {
    scale: 1.2;
}

#menu-close {
    display: flex;
    padding: 5.5vmin 2.5vmin 4vmin;
}

#menu-wrapper {
    width: fit-content;
    position: fixed;
    left: -50%;
    top: 0;
    z-index: 1;
    height: 100%;
    background: white;
    transition: all .5s ease;
}

#menu-wrapper.active {
    left: 0;
}

#action-panel-header div#menu-icon-wrapper,
#action-panel-header div#menu-wrapper {
    display: none;
}