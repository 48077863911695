@media only screen and (max-width:768px) {

    /* Large Heading */
    #auth-image-container>div h3,
    #action-panel-header .brand-logo h2 {
        font-size: 5vmin !important;
        line-height: 5.4vmin;
    }

    /*Medium Heading */
    #auth-form-container h2,
    #offer h2,
    .pie-chart h2,
    #your-business #head h2,
    #logo-page-content h2,
    #business-details #profile-detail>span:first-child,
    #business-details #profile-detail>span:last-child,
    .heading-row span,
    #transaction-filters>h2 {
        font-size: 3.5vmin !important;
        line-height: 4.8vmin;
    }

    /* Medium Size Text */
    #auth-form-container button,
    #user-auth-form>input,
    .sales-dropdown,
    .default-theme-input>input,
    #offer #buttons *,
    div#help-btn+div span,
    #action-panel-leftside,
    .default-theme-btn,
    #detail-data #key,
    #detail-data #value,
    .dashboard .dropdown {
        font-size: 2.8vmin;
        line-height: 3.4vmin;
    }

    /* Small Size Text */
    #user-auth-form p#reset-password-message,
    #auth-form-container p,
    form#user-auth-form p,
    #auth-image-container>div p,
    #user-auth-form label,
    .default-theme-input>label,
    #offer #reasons>div span,
    .dropdown>.dropdown-select,
    .head-card>p,
    #action-panel-header #help-btn>button,
    #table #top>*,
    #searchbar-container .input-default-theme,
    #table table th,
    #table table th,
    #table table td,
    #table table tr>td.table-btn button,
    #paginatoin-wrapper,
    .linear-options>button,
    .dropdown-options>div {
        font-size: 2.2vmin !important;
        line-height: 2.8vmin;
    }

    #auth-essential-layout {
        flex-direction: column;
    }

    #auth-image-container {
        padding: 8vmin;
        flex-direction: row-reverse;
        justify-content: space-around;
        width: calc(100% - 16vmin);
    }

    #auth-image-container img {
        width: 20%;
    }

    #auth-form-container {
        width: 100%;
    }

    form#user-auth-form p {
        margin: 4vmin;
    }

    .label-icon img {
        width: 4.5vmin;
        height: 4.5vmin;
    }

    img[alt="dropdown"] {
        width: 3vmin;
        height: 3vmin;
    }

    #auth-form-container>* {
        max-width: 75vw;
    }

    #auth-form-container form {
        padding: 8vmin;
        width: 75%;
    }

    #auth-image-container #logo-page-content {
        display: none;
    }

    div#bottom-texts-container {
        width: auto;
    }

    #auth-form-container>button {
        width: calc(75% + 4rem);
    }

    #action-panel-leftside {
        padding: 0 2.5vmin;
    }

    .grid-container #action-panel-leftside {
        display: none;
    }

    .grid-container {
        display: block;
    }

    .dashboard {
        display: block;
    }

    .dashboard #options {
        flex-wrap: wrap;
        flex-direction: row;
    }

    .dashboard #right {
        margin: 15px 0;
    }

    #offer #reasons {
        margin: 2vmin 0;
    }

    #offer #reasons img {
        width: 4vmin;
        margin-right: 1vmin;
    }

    #offer #big-image img {
        display: none;
    }

    #offer #buttons {
        display: flex;
        gap: 2vmin;
        justify-content: flex-start;
    }

    #offer #buttons * {
        padding: 1.5vmin 2vmin;
        font-weight: 500;
        font-size: 2vmin;
    }

    #pie-charts-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
    }

    div.pie-chart {
        margin-bottom: 0;
        padding: 3vmin;
        padding-bottom: 0px;
    }

    div.pie-chart-wrapper {
        margin: 2vmin;
        width: 50vmin;
    }

    #action-panel-header .brand-logo {
        gap: 2vmin;
        margin-bottom: 0;
        padding: 0 3vmin;
    }

    #action-panel-header .brand-logo img {
        width: 4.4vmin;
        height: 4.4vmin;
    }

    #action-panel-header {
        padding: 2vmin;
    }

    #action-panel-header #help-btn>button {
        gap: 2vmin;
        padding: 2vmin;
        border: 1px solid black;
    }

    #action-panel-header #help-btn img {
        width: 4vmin;
        height: 4vmin;
    }

    #action-panel-header>div>div {
        padding: 0 2vmin;
    }

    .profile-pricture-wrapper {
        width: 6.5vmin;
        height: 6.5vmin;
    }

    .profile-pricture-wrapper img {
        width: 6.3vmin;
        height: 6.3vmin;
    }

    .head-card {
        padding: 2vmin;
        gap: 2vmin;
    }

    .head-card>div:nth-child(2) p span {
        font-size: 3vmin !important;
        line-height: 3vmin;
    }

    #searchbar-container .input-default-theme {
        padding: 2vmin;
        width: 30vw !important;
    }

    #table #options #searchIcon {
        display: flex;
    }

    #paginatoin-wrapper span {
        display: block;
        margin: auto;
    }

    .head-card>p {
        gap: 1vmin;
    }

    .head-card p>div {
        width: 2.6vmin;
        height: 2.6vmin;
    }

    .head-card p img {
        width: 2vmin;
        height: 2vmin;
    }

    .head-card>div:nth-child(2) {
        gap: 1vmin;
        justify-content: space-between;
    }

    #table,
    #your-business {
        padding: 3vmin;
    }

    #table table tr>td.table-btn button {
        padding: 2vmin 1vmin;
    }

    #table #options #searchIcon {
        margin-left: -5vmin;
        width: 5vmin;
    }

    #searchIcon img {
        width: 3vmin;
        height: 3vmin;
    }

    #table-wrapper {
        display: block;
        overflow-x: scroll;
        width: calc(100vw - 6vmin - 30px);
    }

    .linear-options {
        margin: 0;
        margin-left: 3vmin;
    }

    .linear-options>button {
        padding: 2vmin 3vmin;
        border-radius: 5px;
    }

    #settlements {
        display: flex;
        flex-flow: column nowrap;
    }

    #your-business #head {
        gap: 2vmin;
    }

    table img[alt="copy"] {
        margin-left: 1vmin;
        width: 2.8vmin;
        height: 2.8vmin;
    }

    table img[alt="options"],
    table img[alt="delete"] {
        width: 3.6vmin;
        height: 3.6vmin;
    }

    .default-theme-btn {
        padding: 1.5vmin 2.5vmin;
    }

    table .dropdown-options-wrapper {
        width: fit-content;
        top: 50% !important;
        left: -200% !important;
    }

    table tr:last-child .dropdown-options-wrapper {
        top: -50% !important;
    }

    .linear-options.settings>button {
        padding: 2.6vmin 4vmin;
        border-radius: 1.6vmin;
    }

    #business-details #profile,
    #business-details .details {
        padding: 5vmin 4vmin;
    }

    #detail-data>div {
        gap: 1.6vmin;
    }

    .linear-options.settings {
        overflow-x: auto;
        gap: 2vmin;
        white-space: nowrap;
        padding-bottom: 3vmin;
    }

    #switch-wrapper {
        width: 6vmin;
        height: 3vmin;
    }

    #switch-circle {
        width: 2.6vmin;
        height: 2.6vmin;
    }

    .popup-content .default-theme-input {
        width: auto !important;
    }

    .popup-container>* {
        padding: 3vmin 5vmin;
    }

    .popup-container {
        max-width: 96vw;
    }

    #transaction-filters {
        position: fixed;
        top: 50vh;
        left: 50vw;
        translate: -50% -50%;
        max-height: 85vh;
        overflow-y: auto;
    }

    #transaction-filters>#head {
        padding: 2.4vmin 2.8vmin;
    }

    #transaction-filters label,
    #transaction-filters p {
        font-size: 3.4vmin;
        line-height: 3.6vmin;
    }

    #transaction-filters #head {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #transaction-filters #close {
        display: flex;
    }

    #menu-icon-wrapper {
        display: flex !important;
    }

    #menu-wrapper {
        display: block !important;
    }
}

@media screen and (max-width:540px) {
    .head-card {
        width: 95%;
    }

    .head-cards-container {
        align-items: center;
        margin: 2vmin 0;
        flex-flow: column nowrap;
    }

    #paginatoin-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2vmin;
    }

    #paginatoin-wrapper>div {
        gap: 2vmin;
    }

    .page-actions {
        width: 4.5vmin;
        height: 4.5vmin;
    }

    .page-actions img {
        width: 3vmin;
        height: 3vmin;
    }

    .dashboard #middle {
        display: flex;
        flex-direction: column;
    }

    #action-panel-leftside svg {
        width: 4vmin;
        height: 4vmin;
    }

    #menu-icon-wrapper img {
        width: 5.5vmin;
        height: 5.5vmin;
    }

    #options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    #calender {
        display: flex;
        padding: 2vmin;
    }

    .right-actions-container .dropdown {
        padding: 1vmin;
    }

    #your-business #head>div {
        margin: 2vmin 0;
    }

    #business-details {
        display: flex;
        flex-flow: column nowrap;
    }

    #business-details .profile-pricture-wrapper img {
        width: 36vw;
        height: 36vw;
    }

    #business-details .profile-pricture-wrapper {
        width: 44vw !important;
        height: 44vw !important;
    }
}